/* eslint-disable camelcase */
import { useState, useEffect, Suspense } from "react";// eslint-disable-line no-unused-vars
import { useWeb3React } from "@web3-react/core";
import { Routes, Route } from "react-router-dom";// eslint-disable-line no-unused-vars
import Information from "layouts/information";// eslint-disable-line no-unused-vars
import Select from 'react-select';// eslint-disable-line no-unused-vars

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField"; // eslint-disable-line no-unused-vars
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";
import MDButton from "components/MDButton";

import { notification } from "antd";

// ABI import
import INSCRIPTIONSABI from "../../assets/abi/srcINSCRIPTIONSABI.json";

// Config
import config from "../../config/config";

import "./mint.css";

const ethers = require("ethers");



function Send() {
  const { account } = useWeb3React();
  const [loadingStake1State, setLoadingStake1State] = useState(false); // eslint-disable-line no-unused-vars

  const [loadingClaimState, setLoadingClaimState] = useState(false);// eslint-disable-line no-unused-vars
  const [mybalance, setMybalance] = useState(0);// eslint-disable-line no-unused-vars
  const [sendAmount, setSendAmount] = useState(0);// eslint-disable-line no-unused-vars
  const [sendAddress, setSendAddress] = useState("");// eslint-disable-line no-unused-vars


  const newProvider = new ethers.providers.Web3Provider(window.ethereum);
  const newSigner = newProvider.getSigner();
  const INSCRIPTIONSContract = new ethers.Contract(config.srcINSCRIPTIONS, INSCRIPTIONSABI, newSigner);

  const handleInputChange = (id, value) => {// eslint-disable-line no-unused-vars
    textFieldsRef.current[id] = value
  };


  const inputValueamount = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setSendAmount(value);
  };

  const inputValueaddress = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setSendAddress(value);
  };

  // buy
  const transactionToken = async (_id, _amount) => {// eslint-disable-line no-unused-vars
    setLoadingStake1State(true);
    window.console.log(sendAmount,sendAddress);
    const jsonData = {"p":"src20","op":"transfer","tick":"sbrc","amt":sendAmount, "receiver":sendAddress}
    const heads = 'data:application/json,'
    const dataHex = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(heads+JSON.stringify(jsonData)));
    window.console.log(dataHex);
    await newSigner.sendTransaction({
      to: "0x2133770D31949d00C64a65F43EcBc4BA4239D466",
      value: 0,
      data: dataHex,
    }).then((tx) => {
      tx.wait().then(() => {
        setLoadingStake1State(false);
        notification.success({
          message: "Success",
          description: "Send Successful.",
        });
        startStateFunc();
      });
    });

  };

  const startStateFunc = async () => {
    let balance = 0;
    if (account) {
      balance = await INSCRIPTIONSContract.balanceOfTick("sbrc",account);
      const count = Number(balance.toString());
      setMybalance(count);
    }
  };


  useEffect(() => {
    if (account) {
      startStateFunc();
    }
  }, [account]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="sdoodStakingContainer">
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Send
                </MDTypography>
              </MDBox>
              
              <MDBox p={1}>
                <Grid container spacing={4} mt={3} mb={3}>
                  <Grid item xs={20} xl={30} md={3} mb={3}>
                      <Card>
                        <MDBox pt={5} ml={3} mr={3} mb={3} >
                          <MDTypography variant="h4" textAlign="center">
                              You can Send sbrc to address
                          </MDTypography>
                        </MDBox>

                        <MDBox pt={3} ml={3} mr={3} mb={3} >
                          <MDTypography style={{display: 'flex'}}>
                            <MDTypography pt={1}  variant="h7" textAlign="right"
                              style={{width: "40%",color:"BLACK"}}>
                              sbrc balance:
                            </MDTypography>
                            <MDTypography variant="h6" textAlign="center" style={{width: "80%",color:"BLACK"}}>
                            {mybalance}
                            </MDTypography>
                          </MDTypography>

                          <MDTypography pt={3} style={{display: 'flex'}}>
                            <MDTypography pt={1}  variant="h7" textAlign="right"
                              style={{width: "40%",color:"BLACK"}}>
                              Send Amount:
                            </MDTypography>
                            <MDTypography variant="h7" textAlign="center" style={{width: "80%",color:"BLACK"}}>
                                <TextField
                                  style={{width: "100%",color:"BLACK"}}
                                  key="1"
                                  label="Send Amount"
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={inputValueamount}
                                />
                            </MDTypography>
                          </MDTypography>

                          <MDTypography pt={3} style={{display: 'flex'}}>
                            <MDTypography pt={1}  variant="h7" textAlign="right"
                              style={{width: "40%",color:"BLACK"}}>
                              Send address:
                            </MDTypography>
                            <MDTypography variant="h6" textAlign="center" style={{width: "80%",color:"BLACK"}}>
                                <TextField
                                  style={{width: "100%",color:"BLACK"}}
                                  key="1"
                                  label="Send to address"
                                  type="text"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={inputValueaddress}
                                />
                            </MDTypography>
                          </MDTypography>

                        

                              <MDButton
                                  color="success"
                                  style={{ width: "50%", marginTop: "8%", marginLeft:"25%" }}
                                  onClick={() => transactionToken()}
                                >
                                  <MDTypography variant="h5" textAlign="center" color="white">
                                    {loadingStake1State ? (
                                      <CircularProgress
                                        color="inherit"
                                        style={{ width: "20px", height: "20px" }}
                                      />
                                    ) : (
                                      "Send"
                                    )}
                                  </MDTypography>
                              </MDButton>
                          </MDBox>
                        </Card>
                      </Grid>

                  </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Send;
