/* eslint-disable camelcase */
import { useState, useEffect, useRef } from "react";// eslint-disable-line no-unused-vars
import { useWeb3React } from "@web3-react/core";
import { Routes, Route } from "react-router-dom";// eslint-disable-line no-unused-vars
import Information from "layouts/information";// eslint-disable-line no-unused-vars
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { notification } from "antd";
import Select from 'react-select';// eslint-disable-line no-unused-vars
// ABI import
import INSCRIPTIONSTESTABI from "../../assets/abi/INSCRIPTIONSTESTABI.json";

// Config
import config from "../../config/config";

import "./sdoodstaking.css";

const ethers = require("ethers");


function Myinscriptions() {
  const { account } = useWeb3React();
  const [loadingStake1State, setLoadingStake1State] = useState(false); // eslint-disable-line no-unused-vars
  const [loadingClaimState, setLoadingClaimState] = useState(false);// eslint-disable-line no-unused-vars
  const [selectedOption, setSelectedOption] = useState("Not Sell");// eslint-disable-line no-unused-vars
  const [MinPrice, setMinPrice] = useState(0);// eslint-disable-line no-unused-vars
  const [HoldAmount, setHoldAmount] = useState(0);// eslint-disable-line no-unused-vars

  const textFieldsRef = useRef([]); // eslint-disable-line no-unused-vars
  const handleInputChange = (id, value) => {// eslint-disable-line no-unused-vars
    textFieldsRef.current[id] = value
  };

  const [Shopdata, setShopdata] = useState(); // eslint-disable-line no-unused-vars
  const newProvider = new ethers.providers.Web3Provider(window.ethereum);
  const newSigner = newProvider.getSigner();
  const INSCRIPTIONSContract = new ethers.Contract(config.INSCRIPTIONSTEST, INSCRIPTIONSTESTABI, newSigner);




  const saleCancel = async (_id) => {
    setLoadingClaimState(true);
    await INSCRIPTIONSContract.saleCancelMy(_id).then((tx) => {
      tx.wait().then(() => {
        setLoadingClaimState(false);
        notification.success({
          message: "Success",
          description: "Cancel Successful.",
        });
        getOwnerdata("1");
      });
    });
  };

  const saleMy = async (_id,testid) => {
    setLoadingClaimState(true);
    const aomount = textFieldsRef.current[testid];
    const s  = ethers.utils.parseUnits(aomount.toString(),18)
    if(aomount>0){
      await INSCRIPTIONSContract.saleMy(_id,s).then((tx) => {
        tx.wait().then(() => {
          setLoadingClaimState(false);
          notification.success({
            message: "Success",
            description: "Sell Order Successful.",
          });
          getOwnerdata("1");
        });
      });
    }
  };

  const options = [
    { value: '1', label: 'Not Sell' },
    { value: '2', label: 'In Sell' },
  ];

  const handleSelectChange = (selectedOption) => {// eslint-disable-line no-unused-vars
    if(selectedOption.value === "1"){
      getOwnerdata("1");
      setSelectedOption(selectedOption.label);
    }else if(selectedOption.value === "2"){
      getOwnerdata("2");
      setSelectedOption(selectedOption.label);

    }
  };
  const getres = (res1) =>{
    const res = []
    textFieldsRef.current = []
    let sella = 0;
    for(let i= 0; i < res1.length;i+=1) {
      const ids = res1[i].id.toString();
      const pricenumbing =  res1[i].price.toString() // eslint-disable-line no-unused-vars
      const pricenum =  (res1[i].price/1000000000000000000).toString()
      if (res1[i].price > 0){
        sella += 1;
      }
      const Textid = textFieldsRef.current.length;
      textFieldsRef.current.push(Textid);

      res.push(
        <Grid item xs={12} xl={3} md={3} mb={3}>
          <Card>
          <MDBox
              mx={2}
              mt={-3}
              py={1}
              px={2}
              variant="gradient"
              bgColor="success"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
              SHIBEETLE:#{ids}
              </MDTypography>
            </MDBox>

            <MDBox
              mx={5}
              mt={2}
              py={-20}
              px={1}
              variant="gradient"
              bgColor="success"
              borderRadius="lg"
              coloredShadow="info"

              style={{width: "30%",color:"yellow"}}
            >
              <MDTypography variant="h6" color="yellow" textAlign="center">
                brc mint
              </MDTypography>

            </MDBox>
            <MDBox pt={3} ml={3} mr={3} mb={3}>
              <MDTypography variant="h4" textAlign="center">
                1000/SHIBEETLE
              </MDTypography>                
              {(res1[i].price > 0)?(
                <div>
                <MDTypography variant="h6" textAlign="center" color="black">
                seller:{res1[i].seller.slice(0, 4)}....{res1[i].seller.slice(-4)}<br/>
                </MDTypography>
                <MDTypography variant="h6" textAlign="center" color="black">
                sell:{pricenum}(bnb)
                </MDTypography>
                </div>
              ):(
                
              <TextField
                  style={{ width: "100%", marginTop: "2.5%"}}
                  key={Textid}
                  
                  label="Sell Amount(BNB)"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handleInputChange(Textid, e.target.value)}
                />

              )}
              
              {(res1[i].price > 0)?(
                  <MDButton
                    color="success"
                    style={{ width: "100%", marginTop: "8%" }}
                    onClick={() => saleCancel(ids)}
                  >
                    <MDTypography variant="h6" textAlign="center" color="white">
                      {loadingStake1State ? (
                        <CircularProgress
                          color="inherit"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        "canel sell"
                      )}
                    </MDTypography>
                  </MDButton>
              ):(
                  <MDButton
                    color="success"
                    style={{ width: "100%", marginTop: "8%" }}
                    onClick={() => saleMy(ids,Textid)}
                  >
                    <MDTypography variant="h6" textAlign="center" color="white">
                      {loadingStake1State ? (
                        <CircularProgress
                          color="inherit"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        "sell"
                      )}
                    </MDTypography>
                  </MDButton>

              )}

              
            </MDBox>
          </Card>
        </Grid>
      )
    }
    setMinPrice(sella);
    return res
  };



  const getOwnerdata = async (_index) => {// eslint-disable-line no-unused-vars
    if (account) {
      const res2 = await INSCRIPTIONSContract.getByOwner(account);
      if(res2.length > 0){
        if(_index === "1"){
          const res1 = res2.slice().sort((a, b) => a.price - b.price);
          setMinPrice((res1[0].price/1000000000000000000).toString());
          setHoldAmount(res1.length);
          const res = getres(res1);
          setShopdata(res)
        }else if(_index === "2"){
          const res1 = res2.slice().sort((a, b) => b.price - a.price);
          setMinPrice((res1[res1.length -1].price/1000000000000000000).toString());
          setHoldAmount(res1.length);
          const res = getres(res1);
          setShopdata(res)
        }
      }
    }
  };

  
  useEffect(() => {
    if (account) {
      getOwnerdata("1");
    }
  }, [account]);
  // Staking SDOOD Function


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
      
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="sdoodStakingContainer">
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  My Inscriptions
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
              <MDBox
                  borderRadius="lg"
                  style={{color:"#996699"}}
                >
                  <MDTypography variant="h6" textAlign="center" color="#1A1AFA">
                    In Sell Amount:{MinPrice} | Hold Amount:{HoldAmount}
                    <Select
                        options={options}
                        value={selectedOption}
                        placeholder={selectedOption}
                        onChange={handleSelectChange}
                        style={{ width: "15%", color:"#996699"}}
                      />
                  </MDTypography>
                  
                </MDBox>
              <Grid container spacing={4} mt={3} mb={3}>
                    {Shopdata}
                  </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Myinscriptions;
