import DoodNftStaking from "layouts/doodnftstaking";// eslint-disable-line no-unused-vars
import DedNftStaking from "layouts/dednftstaking";// eslint-disable-line no-unused-vars
import SDoodStaking from "layouts/sdoodstaking";// eslint-disable-line no-unused-vars
import Swap from "layouts/swap";// eslint-disable-line no-unused-vars
import Mint from "layouts/mint";// eslint-disable-line no-unused-vars
import Information from "layouts/information";// eslint-disable-line no-unused-vars
import Market from "layouts/market";// eslint-disable-line no-unused-vars
import Myinscriptions from "layouts/myinscriptions";// eslint-disable-line no-unused-vars
import SEND from "layouts/send";


// @mui icons
import LocalOfferIcon from "@mui/icons-material/LocalOffer";// eslint-disable-line no-unused-vars
import LoyaltyIcon from "@mui/icons-material/Loyalty";// eslint-disable-line no-unused-vars
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import AssessmentIcon from "@mui/icons-material/Assessment";// eslint-disable-line no-unused-vars
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";// eslint-disable-line no-unused-vars

const routes = [
  // {
  //   type: "collapse",
  //   name: "DOODCATs STAKING",
  //   key: "DoodNftStaking",
  //   icon: <LoyaltyIcon />,
  //   route: "/DoodNftStaking",
  //   component: <DoodNftStaking />,
  // },
  // {
  //   type: "collapse",
  //   name: "DEDDOODs STAKING",
  //   key: "DedNftStaking",
  //   icon: <LoyaltyIcon />,
  //   route: "/DedNftStaking",
  //   component: <DedNftStaking />,
  // },
  // {
  //   type: "collapse",
  //   name: "sDOOD STAKING",
  //   key: "SDoodStaking",
  //   icon: <LocalOfferIcon />,
  //   route: "/SDoodStaking",
  //   component: <SDoodStaking />,
  // },
  // {
  //   type: "collapse",
  //   name: "SWAP",
  //   key: "Swap",
  //   icon: <SwapHorizontalCircleIcon />,
  //   route: "/Swap",
  //   component: <Swap />,
  // },
  {
    type: "collapse",
    name: "SEND",
    key: "SEND",
    icon: <MedicalServicesIcon />,
    route: "/SEND",
    component: <SEND />,
  },
  // {
  //   type: "collapse",
  //   name: "MINT",
  //   key: "Mint",
  //   icon: <MedicalServicesIcon />,
  //   route: "/Mint",
  //   component: <Mint />,
  // },
  // {
  //   type: "collapse",
  //   name: "INFORMATION",
  //   key: "Information",
  //   icon: <AssessmentIcon />,
  //   route: "/Information",
  //   component: <Information />,
  // },
  // {
  //   type: "collapse",
  //   name: "MARKET",
  //   key: "market",
  //   icon: <LoyaltyIcon />,
  //   route: "/Market",
  //   component: <Market />,
  // },
  // {
  //   type: "collapse",
  //   name: "Myinscriptions",
  //   key: "Myinscriptions",
  //   icon: <LoyaltyIcon />,
  //   route: "/Myinscriptions",
  //   component: <Myinscriptions />,
  // },

];

export default routes;
