// const switchSongbirdRequest = () =>
//   window.ethereum.request({
//     method: "wallet_switchEthereumChain",
//     params: [{ chainId: "0x13" }],
//   });
// 97: 0x61   56:0x38  137:0x89
const switchBSCTestRequest = () =>
  window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x89" }],
  });

// const addSongbirdRequest = () =>
//   window.ethereum.request({
//     method: "wallet_addEthereumChain",
//     params: [
//       {
//         chainId: "0x13",
//         chainName: "SongBird",
//         rpcUrls: ["https://sgb.ftso.com.au/ext/bc/C/rpc"],
//         blockExplorerUrls: ["https://songbird-explorer.flare.network"],
//         nativeCurrency: {
//           name: "SGB",
//           symbol: "SGB",
//           decimals: 18,
//         },
//       },
//     ],
//   });

const addBSCTestRequest = () =>
  window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x61",
        chainName: "BSCTest",
        rpcUrls: ["https://sgb.ftso.com.au/ext/bc/C/rpc"],
        blockExplorerUrls: ["https://songbird-explorer.flare.network"],
        nativeCurrency: {
          name: "SGB",
          symbol: "SGB",
          decimals: 18,
        },
      },
    ],
  });

// eslint-disable-next-line import/prefer-default-export
export const switchSongbirdNetwork = async () => {
  if (window.ethereum) {
    try {
      // await switchSongbirdRequest();
      await switchBSCTestRequest();
    } catch (error) {
      if (error.code === 4902) {
        try {
          // await addSongbirdRequest();
          // await switchSongbirdRequest();
          await addBSCTestRequest();
          await switchBSCTestRequest();
        } catch (addError) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
};
